<template>
  <div class="container">
    <div class="d-none d-lg-block abc mt-3">
      <!-- <SplashScreen v-if="this.$store.state.isLoading" /> -->
      <b-navbar toggleable="lg" type="dark" class="py-0 d-flex justify-content-between">
        <b-navbar-brand :to="{ name: 'LandingPage' }" class="py-0">
          <img class="" src="../assets/Buyback_content/Redington (India).png" alt="Redington" />
        </b-navbar-brand>
        <!-- <div class="my-2 d-flex justify-content-center">
              <b-button :to="{ name: 'LandingPage' }" style="background-color: transparent;border: none; color: black;font-size: 15px; font-weight: bolder;" >Home</b-button>
              <b-button href="https://redingtongroup.com/mea/about-us/" class="ml-2 " style="background-color: transparent;border: none; color: black;font-size: 15px; font-weight: bolder;">About Us</b-button>
              <b-button href="https://redingtongroup.com/mea/brands/"  class="ml-2 " style="background-color: transparent;border: none; color: black;font-size: 15px; font-weight: bolder;" >Brands</b-button>
              <b-button  href="https://redingtongroup.com/mea/our-services/" class="ml-2 " style="background-color: transparent;border: none; color: black;font-size: 15px; font-weight: bolder;" >Services</b-button>
        </div> -->
        <!-- <div class=" px-4 ">
              <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" class="my-2 ">
                <template #button-content>
                  <span style="font-size: medium;color: black;"><img src="../assets/country/UAE-01.svg" width="25px"/>
                    <b class="ml-2" style="font-size: 15px; font-weight: bolder;">UAE</b>
                  </span>
                </template>
                <b-dropdown-item><img src="../assets/country/UAE-01.svg" width="25px"/><b class="ml-2" style="font-size: 15px; font-weight: bolder;">UAE</b></b-dropdown-item>
                <b-dropdown-item><img src="../assets/country/Qatar-01.svg" width="25px"/><b class="ml-2" style="font-size: 15px; font-weight: bolder;">Qatar</b></b-dropdown-item>
                <b-dropdown-item><img src="../assets/country/Kenya-01.svg" width="25px"/><b class="ml-2" style="font-size: 15px; font-weight: bolder;">KENYA</b></b-dropdown-item>
                <b-dropdown-item><img src="../assets/country/Nigeria.svg" width="25px"/><b class="ml-2" style="font-size: 15px; font-weight: bolder;">Nigeria</b></b-dropdown-item>
                <b-dropdown-item><img src="../assets/country/KSA-01.svg" width="25px"/><b class="ml-2" style="font-size: 15px; font-weight: bolder;">KSA</b></b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="my-2 ">
              <img src="../assets/SVG/Language Icon.svg" width="25px"/><b class=""></b>
              <b-button to="#"  style="background-color: transparent;font-size: 15px; font-weight: bolder;border: none; color: black;" v-b-modal.translate-modal >Language</b-button>
            </div> -->
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="">
            <!-- <div class="my-2 ">
              <b-button :to="{ name: 'LandingPage' }" style="background-color: transparent;border: none; color: black;font-size: 15px; font-weight: bolder;" >Home</b-button>
              <b-button href="https://redingtongroup.com/mea/about-us/" class="ml-3 " style="background-color: transparent;border: none; color: black;font-size: 15px; font-weight: bolder;">About Us</b-button>
              <b-button href="https://redingtongroup.com/mea/brands/"  class="ml-3 " style="background-color: transparent;border: none; color: black;font-size: 15px; font-weight: bolder;" >Brands</b-button>
              <b-button  href="https://redingtongroup.com/mea/our-services/" class="ml-3 " style="background-color: transparent;border: none; color: black;font-size: 15px; font-weight: bolder;" >Services</b-button>
            </div> -->
            <!-- <span style="font-size: medium;color: black;"><img :src="require('@/assets/country/' + Location + '.svg')" width="25px"/>
              <b class="ml-2" style="font-size: 15px; font-weight: bolder;">{{Location}}</b>
            </span> -->
            <div class=" px-3 " >
              <span style="font-size: medium;color: black;"><img :src="require('@/assets/country/' + Location + '.svg')" width="25px"/>
              <b class="ml-2" style="font-size: 15px; font-weight: bolder;">{{Location}}</b>
            </span>
              <!-- <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" class="my-2 ">
                <template #button-content>
                  <span style="font-size: medium;color: black;"><img :src="require('@/assets/country/' + Location + '.svg')" width="25px"/>
                    <b class="ml-2" style="font-size: 15px; font-weight: bolder;">{{Location}}</b>
                  </span>
                </template>
                <b-dropdown-item><img src="../assets/country/United Arab Emirates.svg" width="25px"/><b class="ml-2" style="font-size: 15px; font-weight: bolder;">UAE</b></b-dropdown-item>
                <b-dropdown-item><img src="../assets/country/Qatar-01.svg" width="25px"/><b class="ml-2" style="font-size: 15px; font-weight: bolder;">Qatar</b></b-dropdown-item>
                <b-dropdown-item><img src="../assets/country/Kenya-01.svg" width="25px"/><b class="ml-2" style="font-size: 15px; font-weight: bolder;">KENYA</b></b-dropdown-item>
                <b-dropdown-item><img src="../assets/country/Nigeria.svg" width="25px"/><b class="ml-2" style="font-size: 15px; font-weight: bolder;">Nigeria</b></b-dropdown-item>
                <b-dropdown-item><img src="../assets/country/Saudi Arabia.svg" width="25px"/><b class="ml-2" style="font-size: 15px; font-weight: bolder;">Saudi Arabia</b></b-dropdown-item>
              </b-dropdown> -->
            </div>
            <div class="my-2 ">
              <img src="../assets/SVG/Language Icon.svg" width="25px"/><b class=""></b>
              <b-button
                to="#"
                style="background-color: transparent;font-size: 15px; font-weight: bolder;border: none; color: black;"
                v-b-modal="modalId"
              >
                Language
              </b-button>
            </div> 
            <div class="my-2 ">
              <!-- <img src="../assets/SVG/Language Icon.svg" width="25px"/><b class=""></b>
              <b-button to="#"  style="background-color: transparent;font-size: 15px; font-weight: bolder;border: none; color: black;" v-b-modal.translate-modal >Language</b-button> -->
              <b-modal
                :id="modalId"
                size="sm"
                hide-backdrop
                hide-footer
                hide-header
                >
                <div class="d-flex justify-content-center">
                  <b-button @click="change_language()"  >Arabic (en|ar)</b-button>
                </div>
              </b-modal>
            </div>
            <sign-in class=""/>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <div class="d-lg-none ml-auto mt-2 mb-2 d-flex justify-content-between">
        <b-navbar toggleable="sm"  style="justify-content:left !important">
          <b-navbar-toggle style="border: none !important;"  class="d-flex">
            <b-navbar-brand :to="{ name: 'LandingPage' }" class="py-0">
              <img  src="../assets/Buyback_content/Redington (India).png" alt="Redington"  width="135px"  />
            </b-navbar-brand>
          </b-navbar-toggle>
         
          

          <!-- <b-collapse id="nav-text-collapse" is-nav style="width: 10px;">
            <b-navbar-nav>
              <div class="my-2 text-left" style="color: black !important;">
                  <b-button  style="color: black !important;" v-b-modal.modal-prevent-closing2 class="ml-3  text-decoration-none py-0" variant="link">Track Order</b-button>
                </div>
              <div class="my-2 text-left">
                <b-button to="/faq" class="ml-3  text-decoration-none py-0" variant="link">FAQs</b-button>
                </div>
                <div class="my-2 text-left">
                  <b-button to="/about-sell-old-phone-buy-used-mobile-online-and-mobile-repair-dubai-uae" class="ml-3  text-decoration-none py-0" variant="link">About</b-button>
                </div>
                <div class="my-2 text-left">
                  <b-button to="/contact" class="ml-3  text-decoration-none py-0" variant="link">Contact</b-button>
                </div>
            </b-navbar-nav>
          </b-collapse> -->

        </b-navbar>
        <div class="mt-2"  style="justify-content:right !important" v-if="!this.$store.state.user">
          <sign-in  style="list-style-type: none"/>
        </div>
        <div   style="justify-content:right !important" v-else>
          <sign-in  style="list-style-type: none"/>
        </div>

      <!-- <div class="my-2 mr-3">
        <b-button to="/reuse/sell-gadgets-used-iPad-second-hand-phones-in-dubai-uae" variant="success" style="color: white;max-height: 35px; ">Sell Now</b-button>
      </div>         -->
      <!-- <div class="h2 ml-3">
        <b-button to="/orders" class="ml-4 orders text-decoration-none py-0" variant="link">Orders</b-button>
        <b-button to="/cart" class="ml-4 cart text-decoration-none py-0" variant="link">Cart</b-button>
        <sign-in class="ccc ml-auto"/>
      </div> -->
    </div>
  </div>
</template>

<script>
import api from '../api'
import SignIn from '@/components/SignIn.vue'
import SearchBox from './SearchBox.vue'
import { Translator } from "vue-google-translate";
import SplashScreen from '../components/SplashScreen.vue'
export default {
  name: 'Header',
  props: ['items'],
  components: {
    SearchBox,
    SignIn,
    Translator,
    SplashScreen,
    name: '',
    nameState: null,
    submittedNames: [],
    order: false
  },
  data () {
    return {
      countries: [
        {
          code: "en|en",
          title: "English",
        },
        {
          code: "en|ar",
          title: "Arabic",
        },
      ],
      modalId: `translate-modal-${Math.random().toString(36).substr(2, 9)}`,
    selected: null
    }
  },
  computed: {
    Location: function () {
      return this.$store.state.user.location
    },
  },
  methods: {
    change_language: function () {
      this.$store.commit('SET_Language', 'ar')
      this.$router.push({ name: 'LandingPage2' })
    },
    hideModal: function () {
      this.$bvModal.hide("translate-modal");
    },
    getOrderDetails: function () {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'reuse.reuse',
          'method': 'get_buyback_details',
          'args': {
            'id': this.name
          }
        },
        'modecode': 100
      }
      console.log('RB263')
      api.request('post', 'update', inputs).then((response) => {
        if (response.data.errorcode) {
          this.errormessage = response.data.errormessage
        } else if (response.data.result && typeof response.data.result === 'object') {
          this.order = response.data.result
          this.$bvModal.hide('modal-prevent-closing2')
          this.$refs['my-modal'].show()
        } else if (response.data.result && typeof response.data.result === 'string') {
          this.errormessage = response.data.result
        }
      }).catch((error) => {
        this.errormessage = error
      })
    },
  },
  watch: {
    $route: function (to, from) {
      if (to.name === 'Home') {
        this.$store.commit('SET_SERVICE', null)
      }
    }
  }
}
</script>

<style scoped>
.navbar-dark {
  box-shadow: 1px 0px 5px #444;
}
.cart {
  /* background-image: url('../assets/imgs/cart.png'); */
  background-repeat: no-repeat;
  background-size: 17px;
  padding-left: 24px;
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #333530;
  align-self: center;
}
.cart:hover {
  /* background-image: url('../assets/imgs/cart-clicked.png'); */
  color: #28a745;
}
.orders {
  /* background-image: url('../assets/imgs/orders.png'); */
  background-repeat: no-repeat;
  background-size: 17px;
  padding-left: 24px;
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #333530;
  align-self: center;
}
.orders:hover {
  /* background-image: url('../assets/imgs/orders-clicked.png'); */
  color: #28a745;
}
.ar {
  /* background-image: url('../assets/imgs/ar.png'); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  width: 90px;
  height: 20px;
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #333530;
}
.ar:hover {
  /* background-image: url('../assets/imgs/ar-clicked.png'); */
  color: #28a745;
}
.navbar-dark .navbar-toggler-icon {
  /* background-image: url('../assets/imgs/nav-collapse.svg'); */
}
#nav-collapse .navbar-nav {
  align-items: center;
}
button.navbar-toggler:focus {
  outline: none
}
.h1 {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.h2 {
  display: flex;
}
.ccc{
  list-style-type: none;
  color: black !important;
}
/* .btnn{
font-size:larger;
padding: 10px 20px 10px 20px;
font-weight: 800;
color: #39A536;
border-radius: 7px;
} */
.btnn:hover{
  background-color: #3FAF2B;
  border-color: #3FAF2B;
}
.abc {
  position: relative;
  
  width: wrap;
  top: 0%;
}
.navbar-dark {
  box-shadow: none;
}
.navbar-collapse {
  flex-grow: 0 !important;
  }
</style>
